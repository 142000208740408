import { Controller } from "@hotwired/stimulus"
// import {enter, leave, toggle} from 'el-transition'

export default class extends Controller {
  static targets = ["overlay"]
  isFirstTime = true;

  connect() {
    // document.querySelectorAll('.modal').forEach(modal => {
    //   // add event listener to close modal when escape key is pressed on inputs
    //   modal.querySelectorAll('input').forEach(input => {
    //     input.addEventListener('keydown', (event) => {
    //       if (event.key === 'Escape') {
    //         // Code to close the modal goes here
    //         this.closeModal(modal.id)
    //       }
    //     });
    //   });
    // });
  }

  open(event){
    //check if there is a modal already open
    let openModal = document.querySelector('.modal.open') || document.querySelector('.slideover.open');
    
    
    if(openModal){
      // openModal.style.display = "none";
      // openModal.classList.remove('open');
      this.closeModal(openModal, {clearForm: true, closeOverlay: false});
    }else{
      let overlay = this.overlayTarget;
      overlay.style.display = "block";
      requestAnimationFrame(() => {
        // Then, in the next frame, make it visible
        overlay.style.opacity = 1;
      });
    }

    let modal = document.getElementById(event.currentTarget.dataset.modalName);  

    modal.style.display = "block";
    requestAnimationFrame(() => {
      // Then, in the next frame, make it visible
      if(modal.classList.contains('slideover')){
        modal.style.opacity = 1;
        modal.style.transform = 'translateX(0)';
      }else{
        modal.style.opacity = 1;
        modal.style.transform = 'translateX(-50%) scale(1)';
      }
    });

    modal.classList.add('open');

    document.body.style.overflow = 'hidden'; // Add this line

    // if modal has any input fields, focus on the first one
    // wait until modal is dom loaded

    let inputs = modal.querySelectorAll('input, textarea');
    if(inputs){
      let autofocus_input = modal.querySelector('.autofocus-input');
      if(autofocus_input){
        autofocus_input.focus();
      }
      // add a event listener to close modal when escape key is pressed on inputs
      inputs.forEach(input => {
        input.addEventListener('keydown', (event) => {
          if (event.key === 'Escape') {
          // Code to close the modal goes here
            this.closeModal(modal)
          }
        });
      });
    }

    this.isFirstTime = true; 

    modal.onclick = function(event) {
      event.stopPropagation();
    }

    // add event listener to close modal when clicking outside of modal
    window.onclick = (event) => {
      if (!this.isFirstTime && event.target != modal) {
        this.closeModal(modal)
      }
      this.isFirstTime = false;
    }
  }

  close(event){
    let modal = event.currentTarget.closest('.modal');
    this.closeModal(modal);
  }

  closeModal(modal, options = {clearForm: true, closeOverlay: true}){
    // modal.querySelectorAll('input').forEach(input => {
    //   // Use the same named function for removing the event listener
    //   // Note: Directly using bind here would not work as it creates a new function
    //   // You need to bind this function in the constructor or use a different approach to maintain context
    //   input.removeEventListener('keydown', this.handleEscapeKeyDown.bind(this, modal));
    // });
    // Start the transition
    if(options.closeOverlay){
      this.overlayTarget.style.opacity = 0;
    }

    if(modal.classList.contains('slideover')){
        modal.style.opacity = 0;
        modal.style.transform = 'translateX(100%)';
      }else{
        modal.style.opacity = 0;
        modal.style.transform = 'translateX(-50%) scale(0.7)';
      }

    // Function to hide modal and overlay after transition
    const hideElements = () => {
      if(options.closeOverlay){
        this.overlayTarget.style.display = "none";
      }
      modal.style.display = "none";
      modal.classList.remove('open');
      document.body.style.overflow = 'auto'; // Restore the body overflow

      // Remove the event listener to avoid memory leaks
      modal.removeEventListener('transitionend', hideElements);
    };

    // Listen for the end of the transition to hide the modal and overlay
    modal.addEventListener('transitionend', hideElements, { once: true });

    // if modal has a form, reset it
    let form = modal.querySelector('form');
    if(form && options.clearForm){
      form.reset();
    }
  }

  // handleEscapeKeyDown(modal, event) {
  //   if (event.key === 'Escape') {
  //     this.closeModal(modal, false);
  //   }
  // }
}
