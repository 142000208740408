import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  completeTask(event) {
    event.stopPropagation();
    let completeTaskButtonId = `markAsComplete-${document.getElementById("taskActionsId").value}`
    let button = document.getElementById(completeTaskButtonId)
    let taskElement = document.getElementById(`task-${document.getElementById("taskActionsId").value}`)

    if(document.body.dataset.confettiOnComplete === '1'){
      let viewportWidth = window.innerWidth;
      let viewportHeight = window.innerHeight;

      confetti({
        particleCount: 50,
        spread: 25,
        scalar: 0.5,
        velocity: 50,
        origin: {
          x: taskElement.getBoundingClientRect().left / viewportWidth,
          y: taskElement.getBoundingClientRect().top / viewportHeight
        }
      });
    }
    button.click();
  }

  moveToToday(){
    let moveToTodayButtonId = `moveToday-${document.getElementById("taskActionsId").value}`
    document.getElementById(moveToTodayButtonId).click();
  }

  snoozeSomeday(){
    let snoozeSomedayButtonId = `snoozeSomeday-${document.getElementById("taskActionsId").value}`
    document.getElementById(snoozeSomedayButtonId).click();
  }

  delete(event){
    event.stopPropagation();
    let deleteButtonId = `delete-${document.getElementById("taskActionsId").value}`
    document.getElementById(deleteButtonId).click();
  }

  plan(event){
    event.stopPropagation();
    let task = document.getElementById(`task-${document.getElementById("taskActionsId").value}`)

    let planTaskModal = document.getElementById("plan-task-modal");
    planTaskModal.dispatchEvent(new CustomEvent("openModal"));

    planTaskModal.querySelector("#task-id").value = document.getElementById("taskActionsId").value
    planTaskModal.querySelector("#task-name").innerHTML = task.dataset.taskName
  }

  snooze(event){
    event.stopPropagation();
    let task = document.getElementById(`task-${document.getElementById("taskActionsId").value}`)

    let snoozeTaskModal = document.getElementById("snooze-task-modal");
    snoozeTaskModal.dispatchEvent(new CustomEvent("openModal"));

    snoozeTaskModal.querySelector("#task-id").value = document.getElementById("taskActionsId").value
    snoozeTaskModal.querySelector("#task-name").innerHTML = task.dataset.taskName
  }

  setDeadline(event){
    event.stopPropagation();
    let task = document.getElementById(`task-${document.getElementById("taskActionsId").value}`)
    let setDeadlineModal = document.getElementById("set-deadline-modal");
    setDeadlineModal.dispatchEvent(new CustomEvent("openModal"));

    setDeadlineModal.querySelector("#task-id").value = document.getElementById("taskActionsId").value
    setDeadlineModal.querySelector("#task-name").innerHTML = task.dataset.taskName
  }

  openLink(){
    let taskId = `task-${document.getElementById("taskActionsId").value}`
    document.getElementById(taskId).querySelector(".task-link-button").click();
  }

  remind(event){
    event.stopPropagation();
    let task = document.getElementById(`task-${document.getElementById("taskActionsId").value}`)

    let remindTaskModal = document.getElementById("create-reminders-modal");
    remindTaskModal.dispatchEvent(new CustomEvent("openModal"));

    remindTaskModal.querySelector("#task-id").value = document.getElementById("taskActionsId").value
    remindTaskModal.querySelector("#task-name").innerHTML = task.dataset.taskName
  }
}
