import { Controller } from "@hotwired/stimulus"
import {Draggable, Sortable} from '@shopify/draggable';

export default class extends Controller {
  connect() {
    const draggable = new Sortable(document.querySelectorAll('.tasks-list'), {
      draggable: '.draggable',
      dropzone: '.tasks-list'
    });

    let previousDropzone = null;

    draggable.on('drag:start', (evt) => {
      // Apply transition only when scaling up
      evt.data.source.style.transform = 'scale(1.05)';
    });

    draggable.on('drag:stop', (evt) => {
      // Immediately remove the transition effect after scaling down to avoid affecting movement
      evt.data.source.style.transition = 'transform 0.2s';
      evt.data.source.style.transform = 'scale(1)';
      // Use a timeout to remove the transition, allowing the scale down transition to complete
      setTimeout(() => {
        evt.data.source.style.transition = '';
      }, 200); // Match the duration of the transition
    });

    draggable.on('sortable:sorted', (evt) => {

      // // If there was a previous dropzone, reset its background color
      // if (previousDropzone && previousDropzone !== evt.data.newContainer.parentElement) {
      //   previousDropzone.parentElement.classList.remove('bg-stone-50 dark:bg-stone-900');
      // }

      // Highlight the new dropzone
      // evt.data.newContainer.parentElement.classList.add('bg-stone-50 dark:bg-stone-900');

      // Update the previous dropzone to the current one
      previousDropzone = evt.data.newContainer
    });

    draggable.on('sortable:stop', (evt) => {
      if(evt.data.newContainer.dataset.day){
        let startDateInput = evt.data.dragEvent.source.querySelector('input[name="start_date"]');
        startDateInput.value = evt.data.newContainer.dataset.day;

        // Create a new 'change' event
        let event = new Event('change', {
          bubbles: true,
          cancelable: true,
        });

        // Dispatch it
        startDateInput.dispatchEvent(event);
      }

      if(evt.data.newContainer.dataset.action == 'snoozeSomeday'){
        evt.data.dragEvent.source.querySelector('#snoozeSomeday').form.requestSubmit();
      }

      if(evt.data.newContainer.dataset.action == 'moveToInbox'){
        evt.data.dragEvent.source.querySelector('#moveToInbox').form.requestSubmit();
      }

      // Reset the background color of all dropzones
      document.querySelectorAll('#tasks-list').forEach(container => {
        container.parentElement.classList.remove('bg-stone-50 dark:bg-stone-900');
      });

      // Clear the previous dropzone reference
      previousDropzone = null;
    });

  }
}
