import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("mouseover", () => {
      document.getElementById("taskActionsId").value = this.element.dataset.taskId;
    });
  }

    // this.element.addEventListener("mouseout", () => {
    //   document.getElementById("taskActionsId").value = null;
    // });
  openTask(){
    this.element.querySelector('.view-task-button').click();
  }
}
