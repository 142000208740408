import { Editor } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import { Controller } from "@hotwired/stimulus"
import FileHandler from '@tiptap-pro/extension-file-handler'
import Image from '@tiptap/extension-image'

import * as Bytescale from "@bytescale/sdk";


export default class extends Controller {
  static targets = ['editor', 'boldButton', 'headingButton', 'realInputField']

  connect() {
    this.uploadManager = new Bytescale.UploadManager({
      apiKey: "public_12a1yz6vdnNV1DQqyBykzEMyUvpq"
    });

    this.editor = new Editor({
      editorProps: {
        attributes: {
          class: 'focus:outline-none p-4 h-dvh',
        },
      },
      element: this.editorTarget,
      extensions: [
        StarterKit,
        Image,
        FileHandler.configure({
          allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
          onDrop: (currentEditor, files, pos) => {
            files.forEach(file => {
              const fileReader = new FileReader()

              fileReader.readAsDataURL(file)
              fileReader.onload = () => {
                currentEditor.chain().insertContentAt(pos, {
                  type: 'image',
                  attrs: {
                    src: fileReader.result,
                  },
                }).focus().run()
              }
            })
          },
          onPaste: (currentEditor, files, htmlContent) => {
            files.forEach(file => {
              if (htmlContent) {
                // if there is htmlContent, stop manual insertion & let other extensions handle insertion via inputRule
                // you could extract the pasted file from this url string and upload it to a server for example
                console.log(htmlContent) // eslint-disable-line no-console
                return false
              }

              const fileReader = new FileReader()

              fileReader.readAsDataURL(file)
              fileReader.onload = async () => {
                try {
                  let fileUrl = await this.handleFileUpload(file);
                  // insert image into editor after upload
                  currentEditor.chain().insertContentAt(currentEditor.state.selection.anchor, {
                    type: 'image',
                    attrs: {
                      src: fileUrl
                    },
                  }).focus().run();
                } catch (e) {
                  console.error('Upload failed:', e);
                }
              }
            })
          },
        }),
      ],
      content: this.realInputFieldTarget.value,
    })

    this.editor.on('update', () => {
      const html = this.editor.getHTML()
      this.realInputFieldTarget.value = html
      this.realInputFieldTarget.form.requestSubmit()
    })
  }

  setBold(event) {
    this.editor.chain().focus().toggleBold().run()
    if (event.currentTarget.classList.contains('active-editor-button')) {
      event.currentTarget.classList.remove('active-editor-button')
      event.currentTarget.classList.add('inactive-editor-button')
    } else {
      event.currentTarget.classList.add('active-editor-button')
      event.currentTarget.classList.remove('inactive-editor-button')
    }
  }

  setHeading(event) {
    let level = parseInt(event.currentTarget.dataset.headingLevel)
    this.editor.chain().focus().toggleHeading({ level: level }).run()
  }

  async handleFileUpload(file) {
    try {
      const { fileUrl, filePath } = await this.uploadManager.upload({ data: file });
      return fileUrl
    } catch (e) {
      alert(`Error:\n${e.message}`); // Alert the error message if the upload fails
    }
  }
}
