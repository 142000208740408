import { Controller } from "@hotwired/stimulus";
import tippy from 'tippy.js';


export default class extends Controller {
  static targets = ['button', 'dropdown']

  connect() {
    this.tippyInstane = tippy(this.buttonTarget, {
      content: this.dropdownTarget.innerHTML,
      allowHTML: true,
      trigger: 'click',
      interactive: true,
      arrow: false,
      placement: 'bottom-end',
      onTrigger: (instance, event) => {
        event.preventDefault(); 
        event.stopPropagation()
      },
      hideOnClick: 'false',
    });
  }
}

