import { Controller } from "@hotwired/stimulus"
import {install} from '@github/hotkey'
import tippy, {followCursor} from 'tippy.js'

export default class extends Controller {
  initialize() {
    
    // let shortcutTemplate = document.getElementById('hotkey-template')

    for (const el of document.querySelectorAll('[data-hotkey]')) {
      install(el)
      // let shortcutKey = this.formatShortcutKey(el.dataset.hotkey)
      // tippy(el, {
      //   content: shortcutTemplate.innerHTML.replace(/%hotkey%/g, shortcutKey),
      //   delay: [200,0],
      //   arrow: false,
      //   allowHTML: true,
      //   inertia: true,
      //   theme: 'no-padding',
      //   animation: 'shift-toward',
      // })
    }
  }

  formatShortcutKey(key) {
    const replacements = {
      'Alt': '⌥',
      'Cmd': '⌘',
      'Control': 'Ctrl',
      'Shift': '⇧',
      '+': '',
      'Space': 'Space',
    };

    return key.split('+').map(part => replacements[part] || part).join('');
  }

  // disconnect() {
  //   this.tooltips.forEach(tooltip => tooltip.destroy())
  // }
}
